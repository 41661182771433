
require("../scss/system.scss");

$ = require("jquery"),
    require('jquery-ui-bundle');

window.jQuery = $;
window.$ = $;

require('babel-polyfill');
import { Modal } from 'bootstrap';

const $dropzone = $('.dropzone');

const processHideDropzone = debounce(() => hideDropzone());

let timer;

function debounce(func, timeout = 100) {

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}


window.escapeHtml = function(untrustedString)
{
    var div = document.createElement('div');
    var node = document.createTextNode(untrustedString);
    div.appendChild(node);
    return div.innerHTML;
};

window.show_error = function(err)
{
    document.querySelector('#cabinet-error-message .alert-description').innerHTML = escapeHtml(err);

    if (err == '') {
        document.getElementById('cabinet-error-message').style.display = 'none';
    } else {
        document.getElementById('cabinet-error-message').style.display = '';
        $('html, body').animate({
           scrollTop: $("#cabinet-error-message").offset().top
       }, 1000);
    }
};

window.changeSort = function(type)
{
    var elem = document.getElementById('change_sort_' + type);

    if (elem.classList.contains('sort-desc')) {
        var sort = '';
    } else if (elem.classList.contains('sort-asc')) {
        var sort = 'desc';
    } else {
        var sort = 'asc';
    }

    elem.classList.remove('sort-desc', 'sort-asc');
    if (sort != '') {
        elem.classList.add('sort-' + sort);

        if (sort == 'desc') {
            ORDER_BY = type + ' desc';
        } else {
            ORDER_BY = type;
        }

    } else {
        ORDER_BY = '';
    }

    updateGalleryContent();
}

window.showFileData = function(file_id, filename, type, access_token)
{
    var domain = BASE_URL;
    if (cname != '') {
        domain = cname;
    }

    var file_link = domain + '/cdn/files/' + file_id + '/' + filename;
    if (access_token.length > 0) {
        file_link += '?token=' + access_token;
    }
    document.querySelector('#file_details_modal .modal-title').innerHTML = '#' + file_id + ' ' + filename;
    document.getElementById('file_path').value = file_link;

    document.querySelectorAll('#file_details_modal .file-type').forEach(function(elem) {
      elem.style.display = 'none';
    });
    document.querySelectorAll('#file_details_modal .file-type-' + type).forEach(function(elem) {
      elem.style.display = '';
    });

    if (type == 'image') {

        window.img_html = '<img style="margin-bottom: 20px;" src="'+ file_link +'" alt="" />';
        var html = img_html + '<br>';

        var thumb = (access_token.length > 0) ? file_link + '&width=500' : file_link + '?width=500';
        html += '<pre>&lt;a href=&quot;'+ file_link +'&quot;&gt;\r\n    &lt;img src=&quot;'+ thumb +'&quot; alt=&quot;&quot; /&gt;\r\n&lt;/a&gt;</pre>';

        if (opener != '') {

            html += '<br>';
            html += '<div class="button-list helper">';
            html +=    '<button onclick="sendPostMessage({html: window.img_html});" class="btn btn-danger">Insert</button>';
            html += '</div>';
        }

        document.querySelector('#file_details_modal .file-type-image').innerHTML = html;
    }

    var myModal = new Modal(document.getElementById('file_details_modal'));
    myModal.show();
}

window.initFileupload = function()
{
    var url = BASE_URL + "/ajax/json/file_upload/?site_id=" + site_id + "&token=" + token;
    url += '&access_type=' + (($('#fileupload_access_by_token').is(':checked')) ? 'token' : 'public');
    url += '&folder_id=' + FOLDER_ID;

    $('#fileupload').fileupload({
        url: url,
        dataType: 'json',
        maxChunkSize: 4194304, // 4M
        add: function(e, data) {
            $('.dropzone').addClass('loading');
            if(!$('.dropzone').hasClass('show'))
                $('.dropzone').addClass('show')

            $('#common-progressbar').css('width', 0);
            data.submit();
        },
        done: function (e, data) {

            if (!data.result.success) {
                show_error(data.result.error);
            } else {
                show_error('');
                $('#files').fadeIn(300).delay(3500).fadeOut(300);
                updateGalleryContent();
            }

            window.fileupload_progress_timeout = setTimeout(function(){
                document.querySelector('#file-upload-progress .progress-bar').style.width = '0';
                $('#common-progressbar').css('width', 0);
                processHideDropzone();
            }, 3000);
        },
        progressall: function (e, data) {
            clearTimeout(window.fileupload_progress_timeout);
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('#file-upload-progress .progress-bar').css(
                'width',
                progress + '%'
            );
            $('#common-progressbar').css(
                'width',
                progress + '%'
            );
        },
        complete: function () {
            //processHideDropzone();
        }
    }).prop('disabled', !$.support.fileInput)
        .parent().addClass($.support.fileInput ? undefined : 'disabled');
}

window.changeFolderId = function(new_folder_id)
{
    FOLDER_ID = new_folder_id;
    OFFSET = 0;

    if (MODE == 'folder') {
        updateFolderContent();
    } else if (MODE == 'gallery') {
        updateGalleryContent();
        initFileupload();
    }
}

window.updateGalleryContent = function()
{
    console.log("update...");
    if (MODE == 'folder') {
        return updateFolderContent();
    }

    $('.gallery-container .gallery-loading').addClass('show');

    postData(BASE_URL + '/ajax/json/gallery/get/?site_id=' + site_id + '&token=' + token, {
        filters: new URLSearchParams(new FormData(document.getElementById('gallery_filter_form'))).toString(),
        from: OFFSET,
        folder_id: FOLDER_ID,
        limit: PAGE_SIZE,
        order: ORDER_BY,
    })
        .then((data) => {
        if (!data.success) {
            show_error(data.error);
        } else {
            document.getElementById('gallery_files').innerHTML = data.files_html;
            initDraggable();
        }
        })
        .finally(()=>{
            $('.gallery-container .gallery-loading').removeClass('show');
            }
        )
    ;
};

window.updateFolderContent = function()
{
    postData(BASE_URL + '/ajax/json/folder/get/?folder_id=' + folder_id + '&token=' + token, {
        from: OFFSET,
        folder_id: FOLDER_ID,
        limit: PAGE_SIZE,
        order: ORDER_BY,
    }).then((data) => {
        if (!data.success) {
            show_error(data.error);
        } else {
            document.getElementById('gallery_files').innerHTML = data.files_html;
        }
    });
};

window.initDraggable = function()
{
    $('.one-item-preview').draggable({
        containment: "#gallery_files",
        revert: true,
        opacity: 0.35,
        revertDuration: 0
    });

    $('.one-item-preview.folder').droppable({
        over: function(event, ui) {
            $(this).addClass('hover');
        },
        out: function(event, ui) {
            $(this).removeClass('hover');
        },
        drop: function(event, ui) {
            postData(BASE_URL + '/ajax/json/gallery/move/?site_id=' + site_id + '&token=' + token, {
                item_id: $(ui.draggable).data('id'),
                item_type: $(ui.draggable).data('type'),
                folder_id: $(this).data('id'),
            }).then((data) => {
                if (!data.success) {
                    show_error(data.error);
                } else {
                    $(ui.draggable).remove();
                }
            });

            $(this).removeClass('hover');
        }
    });
}

window.filesBulkSubmit = function(action)
{
    var elements = [];
    $('.list-item-check-input:checked').each(function(i, el) {
        elements.push($(el).data('id'));
    });

    if (elements.length === 0) {
        return false;
    }

    postData(BASE_URL + '/ajax/json/gallery/files-bulk-actions/?site_id=' + site_id + '&token=' + token, {
        files: elements.join(','),
        bulk_action: action,
    }).then((data) => {
        if (!data.success) {
            show_error(data.error);
        } else {
            updateGalleryContent();
        }
    });

    return false;
};

window.sendPostMessage = function(message)
{
    window.parent.postMessage(message, '*');
};

window.addNewFolder = function()
{
    postData(BASE_URL + '/ajax/json/gallery/add-folder/?site_id=' + site_id + '&token=' + token, { folder_id: FOLDER_ID })
        .then((data) => {
            if (!data.success) {
                show_error(data.error);
            } else {
                OFFSET = 0;
                updateGalleryContent();
            }
        });
}

window.showFolderEdit = function(folder_id)
{
    var name = document.getElementById('folder_name_' + folder_id).innerHTML;
    document.getElementById('edit_folder_name').value = name;
    document.getElementById('edit_folder_id').value = folder_id;

    folder_edit_modal = new Modal(document.getElementById('folder_edit_modal'));
    folder_edit_modal.show();
}

window.showFolderRemove = function(folder_id, folder_idp)
{
    document.getElementById('remove_folder_id').value = folder_id;
    document.getElementById('remove_folder_idp').value = folder_idp;

    folder_remove_modal = new Modal(document.getElementById('folder_remove_modal'));
    folder_remove_modal.show();
}

window.saveFolderName = function()
{
    var folder_id = document.getElementById('edit_folder_id').value;
    var name = document.getElementById('edit_folder_name').value;

    postData(BASE_URL + '/ajax/json/gallery/rename-folder/?site_id=' + site_id + '&token=' + token, {
        folder_id: folder_id,
        name: name,
    }).then((data) => {
        if (!data.success) {
            show_error(data.error);
        } else {
            document.getElementById('folder_name_' + folder_id).innerHTML = name;
        }

        folder_edit_modal.hide();
    });
}

// https://developer.mozilla.org/ru/docs/Web/API/Fetch_API/Using_Fetch
async function postData(url = '', data = {})
{
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json' // 'application/x-www-form-urlencoded'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return await response.json(); // parses JSON response into native JavaScript objects
}

window.removeFolder = function()
{
    var folder_id = document.getElementById('remove_folder_id').value;

    postData(BASE_URL + '/ajax/json/gallery/remove-folder/?site_id=' + site_id + '&token=' + token, { folder_id: folder_id })
        .then((data) => {
            folder_remove_modal.hide();

            if (!data.success) {
                show_error(data.error);
            } else {
                changeFolderId(document.getElementById('remove_folder_idp').value);
            }
        });
}


function hideDropzone() {
    $('.dropzone').removeClass('show')
    setTimeout(() => $('.dropzone').removeClass('loading'), 400)
}
document.addEventListener('DOMContentLoaded', () => {
    $('html')
        .on('dragover', () => {
            clearTimeout(timer)
            if(!$('.dropzone').hasClass('show'))
                $('.dropzone').addClass('show')
        })
    $('html')
        .on('dragleave', () => processHideDropzone())
    $('html')
        .on('dragend`', () => processHideDropzone())
});
